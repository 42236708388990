import React from "react";
import { ReactComponent as ArrowSvg } from "../assets/arrow.svg";
import { Button } from "../components/Button";
import { AnimeText } from "../components/AnimatedText";
import { motion } from "framer-motion";

export const Section7 = (props) => {
  return (
    <section className="section7 relative">
      <div className="bg1 absolute z-0 h-full w-full left-0 top-0"></div>
      <div className="bg2 absolute z-0 h-full w-full left-0 top-0"></div>

      <div className="container z-10 mx-auto md:h-[490px] px-0 lg:px-44 pt-16 md:pt-0 flex flex-col md:flex-row items-center justify-center md:justify-between relative">
        <div className="max-w-xl text-blue text-center md:text-left mb-4 px-6 md:pr-0 lg:px-0">
          <p className="text-3xl md:text-4xl uppercase mb-[15px] text-center md:text-left">
            <AnimeText>
              Discover exciting
              <br className="hidden md:block"/>
              <b> career opportunities!</b>
            </AnimeText>
          </p>
          <p className="mb-[30px] text-center md:text-left">
            <AnimeText>
              Join our Nikon SLM family and become part of a team committed to
              fostering innovation and excellence together.
            </AnimeText>
          </p>
          <Button
            href="https://careers.slm-solutions.com/"
            target="_blank"
            className="bg-blue hover:bg-blue-700 text-white py-2 md:py-2.5 px-[13px] md:px-6 text-base"
          >
            OPEN POSITIONS
            <motion.span
              className="ml-3 stroke-white"
              initial={{ transform: "translateX(-100%)", opacity: "0" }}
              whileInView={{ transform: "translateX(0)", opacity: "1" }}
              transition={{ duration: 0.75 }}
              viewport={{ once: true }}
            >
              <ArrowSvg />
            </motion.span>
          </Button>
        </div>

          <div className="openJobs max-w-full md:max-w-xl lg:-mr-44 xl:-mr-32 bg-center md:bg-left w-[455px] md:w-full h-[423px] md:h-full"></div>
      </div>

      <div className="box top-[68%] md:top-[50%] w-[60vw] md:w-[30vw]">
        <motion.div
          className="box1"
          initial={{ width: "1px", opacity: "0" }}
          whileInView={{
            width: "50%",
            opacity: "1",
            transform: "translateX(-100%)",
            transitionEnd: {
              animationName: "boxEndTransition",
              animationDuration: "1s",
              animationFillMode: "forwards",
            },
          }}
          transition={{
            duration: 2,
            repeatType: "reverse",
            repeat: 1,
            ease: [0.5, 1, 0.1, 1],
          }}
          viewport={{ once: true }}
        ></motion.div>
        <motion.div
          className="box2"
          initial={{ width: "1px", opacity: "0" }}
          whileInView={{
            width: "50%",
            opacity: "1",
            transform: "translateX(-100%)",
            transitionEnd: {
              animationName: "boxEndTransition",
              animationDuration: "1s",
              animationFillMode: "forwards",
            },
          }}
          transition={{
            duration: 2.1,
            repeatType: "reverse",
            repeat: 1,
            ease: [0.5, 1, 0.1, 1],
          }}
          viewport={{ once: true }}
        ></motion.div>
        <motion.div
          className="box3"
          initial={{ width: "1px", opacity: "0" }}
          whileInView={{
            width: "50%",
            opacity: "1",
            transform: "translateX(-100%)",
            transitionEnd: {
              animationName: "boxEndTransition",
              animationDuration: "1s",
              animationFillMode: "forwards",
            },
          }}
          transition={{
            duration: 2.2,
            repeatType: "reverse",
            repeat: 1,
            ease: [0.5, 1, 0.1, 1],
          }}
          viewport={{ once: true }}
        ></motion.div>
      </div>
    </section>
  );
};
