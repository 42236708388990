import React, { useEffect, useRef } from "react";

export const StickyNavbar = (props) => {
  const navRef = useRef(null);

  const stickNavbar = () => {
    if (window !== undefined) {
      window.scrollY > window.innerHeight - 150
        ? props.setStickyClass("fixed sticky-navbar")
        : props.setStickyClass("");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);

  return (
    <>
      <div
        style={{ height: '94px' }}
        className={props.stickyClass ? '' : 'hidden'}
      ></div>

      <div className={`z-50 w-full ${props.stickyClass}`} ref={navRef}>
        {props.children}
      </div>
    </>
  );
};
