import React, { useRef } from "react";
import { ReactComponent as ArrowSvg } from "../assets/arrow.svg";

export const Slider = (props) => {
  const ref = useRef(null);

  const prevClick = () => {
    ref.current?.scrollBy({
      top: 0,
      left: -150,
      behavior: "smooth",
    });
  };

  const nextClick = () => {
    ref.current?.scrollBy({
      top: 0,
      left: 150,
      behavior: "smooth",
    });
  };

  return (
    <div className="slider">
      {props.Button && (
        <div className="actions flex justify-end gap-x-4 mb-5 mr-5 lg:mr-32">
          <button
            onClick={prevClick}
            className="bg-white rounded w-[41px] h-[36px] flex items-center justify-center stroke-blue rotate-180 border border-transparent hover:scale-110 hover:border-blue"
          >
            <ArrowSvg />
          </button>
          <button
            onClick={nextClick}
            className="bg-white rounded w-[41px] h-[36px] flex items-center justify-center stroke-blue border border-transparent hover:scale-110 hover:border-blue"
          >
            <ArrowSvg />
          </button>
        </div>
      )}

      <div
        ref={ref}
        className="slider-items flex  gap-x-5 overflow-x-auto overflow-y-hidden select-none"
      >
        {props.children}
      </div>
    </div>
  );
};
