import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ParallaxProvider } from "react-scroll-parallax";

ReactDOM.createRoot(document.getElementById("root")).render(
  <ParallaxProvider>
    <App />
    {/* <React.StrictMode>
    </React.StrictMode> */}
  </ParallaxProvider>
);
