import React from "react";
import { Button } from "../components/Button";
import { Slider } from "../components/Slider";
import { AnimeText } from "../components/AnimatedText";
import { motion } from "framer-motion";
import { ReactComponent as ArrowSvg } from "../assets/arrow.svg";
import Image1 from "../assets/section2-NXG-600E.png";
import Image2 from "../assets/section2-NXG-XII-601.png";
import Image3 from "../assets/section2-SLM500.png";
import Image4 from "../assets/section2-SLM280-PS.png";
import Image5 from "../assets/section2-SLM280-3.png";
import Image6 from "../assets/section2-SLM800.png";
import Image7 from "../assets/section2-SLM125.png";
import LargeGroup from "../assets/large-group.png";

export const Section2 = (props) => {
  const products = [
    {
      link: "https://am.slm-solutions.com/nxg-xii-600e/",
      image: Image1,
      label: "NXG 600E",
      imgClass: "px-2",
    },
    {
      link: "https://www.slm-pushing-the-limits.com/",
      image: Image2,
      label: "NXG XII 600",
      imgClass: "px-2",
    },
    {
      link: "https://www.slm-solutions.com/products-and-solutions/machines/slm-500/",
      image: Image3,
      label: "SLM®500",
      imgClass: "px-2 -mb-3",
    },
    {
      link: "https://www.slm-solutions.com/products-and-solutions/machines/slm-280-production-series/",
      image: Image4,
      label: "SLM®280 PS",
      imgClass: "px-4",
    },
    {
      link: "https://www.slm-solutions.com/products-and-solutions/machines/slm-280/",
      image: Image5,
      label: "SLM®280 2.0",
      imgClass: "px-2 -mb-2",
    },
    {
      link: "https://www.slm-solutions.com/products-and-solutions/machines/slm-800/",
      image: Image6,
      label: "SLM®800",
      imgClass: "px-2 -mb-1",
    },
    {
      link: "https://www.slm-solutions.com/company/news/detail/slm-solutions-acquires-adira-addcreative-technology-a-game-changer-for-ultra-large-part-production/",
      image: LargeGroup,
      label: "LARGE FORMATS",
      imgClass: "",
    },
    {
      link: "https://www.slm-solutions.com/products-and-solutions/machines/slm-125/",
      image: Image7,
      label: "SLM®125",
      imgClass: "px-2",
    }
  ];

  return (
    <section className="section2 pb-[71px] bg-white relative z-30">
      <div className="sm:text-center items-start sm:items-center flex flex-col mx-auto max-w-2xl md:pt-20 md:pb-16 pt-16 pb-12 px-6 md:px-0">
        <h2 className="uppercase text-3xl md:text-4xl text-blue pb-3.5">
          <AnimeText>
            Elevate Manufacturing
            <br />
            <b>Precision with OUR Machines</b>
          </AnimeText>
        </h2>
        <p className="text-blue">
          <AnimeText>
            Delve into our pioneering 3D metal printing solutions, setting new
            standards in manufacturing capabilities. Empowering innovation
            across industries, from space, automative, energy, defence and
            beyond.
          </AnimeText>
        </p>
        <Button
          href="https://www.slm-solutions.com/products-and-solutions/machines/"
          target="_blank"
          className="text-white bg-blue hover:bg-blue-700 mt-8 py-2 md:py-2.5 px-[13px] md:px-6"
        >
          SEE ALL PRODUCTS
          <motion.span
            className="ml-3 stroke-white"
            initial={{ transform: "translateX(-100%)", opacity: "0" }}
            whileInView={{ transform: "translateX(0)", opacity: "1" }}
            transition={{ duration: 0.75 }}
            viewport={{ once: true }}
          >
            <ArrowSvg />
          </motion.span>
        </Button>
      </div>

      <div className="container mx-auto">
        <Slider Button={true}>
          {products.map((product, index) => (
            <div//framer.com/m/framer/Animator.js
              key={index}
              className="item bg-[#F5F5F5] rounded pt-[30px] h-[364px] w-[250px] pb-10 inline-flex flex-col flex-shrink-0 justify-between"
            >
              <a
                href={product.link}
                target="_blank"
                className="flex w-full justify-between items-center px-[18px] font-bold text-1xl text-blue stroke-blue"
                rel="noreferrer"
              >
                <span className="text-ellipsis whitespace-nowrap">{product.label}</span>
                <ArrowSvg />
              </a>
              <img
                src={product.image}
                alt={product.label}
                className={`${product.imgClass} max-h-[190px] self-center rounded`}
              />
              {/* <span className="lines">
                <LinesSvg />
              </span> */}
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};
