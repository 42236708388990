import React, { useState } from "react";
import { ReactComponent as Arrow } from "../assets/arrow-menu.svg";
import { Popover, Transition } from "@headlessui/react";

export const Dropdown = ({ label, children, className }) => {
  const [isShow, setIsShow] = useState(false);

  return (
    <Popover
      className="dropdown relative inline-block text-left group"
      onMouseLeave={() => setIsShow(false)}
    >
      <Popover.Button
        className="pbutton flex items-center outline-none gap-x-2 group-hover:opacity-60 transition"
        onMouseEnter={() => setIsShow(true)}
      >
        {label}
        <Arrow
          style={{ pointerEvents: "none" }}
          className="group-hover:opacity-60"
        />
      </Popover.Button>
      <Transition
        show={isShow}
        className="pcontainer flex justify-center"
        enter="transition ease-out duration-500"
        enterFrom="transform opacity-0 scale-95 translate-y-[-2rem]"
        enterTo="transform opacity-100 scale-100 translate-y-[0]"
        leave="transition ease-in duration-200"
        leaveFrom="transform opacity-100 scale-100 translate-y-[0]"
        leaveTo="transform opacity-0 scale-95 translate-y-[-2rem]"
      >
        <Popover.Panel className={`absolute z-50 ${className}`}>
          {children}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};
