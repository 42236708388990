import React, { useState } from "react";
import { Navigation } from "../components/Navigation";
import HeaderBg from "../assets/header-bg.mp4";
import { Button } from "../components/Button";
import { AnimeText } from "../components/AnimatedText";
import { StickyNavbar } from "../components/StickyNavbar";
import { Parallax } from "react-scroll-parallax";

function stopLoading() {
  const loader = document.getElementById("loader");
  if (loader) {
    loader.style.opacity = "0";
    setTimeout(() => loader.remove(), 1200);
  }
}

export const Header = (props) => {
  const [stickyClass, setStickyClass] = useState(null);

  return (
    <section className="header pt-6 pb-[101px] min-h-screen flex relative">
      <div className="flex flex-col justify-between flex-grow">
        <StickyNavbar stickyClass={stickyClass} setStickyClass={setStickyClass}>
          <Navigation isSticky={stickyClass}></Navigation>
        </StickyNavbar>

        <div className="container px-6 lg:px-[100px] mx-auto mt-10 z-10">
          <Parallax speed={-15} className="flex flex-col">
            <div className="mb-[21px]">
              <h1 className="text-header md:text-5xl text-white">
                <AnimeText>
                  OUR MISSION
                  <br />
                  <b>TO EMPOWER YOURS</b>
                </AnimeText>
                <br />
              </h1>
              <p className="text-yellow font-semibold text-lg md:text-base">
                <AnimeText>
                  Discover the limitless potential of our selective laser
                  melting technology.
                </AnimeText>
              </p>
            </div>

            <p className="text-white max-w-2xl mb-[30px] text-sm lg:text-base">
              <AnimeText>
                Explore boundless possibilities with our cutting-edge selective
                laser melting technology. Unleash innovation and precision in
                additive manufacturing with Nikon SLM Solutions.
              </AnimeText>
            </p>

            <div className="flex gap-x-6">
              <Button
                href="https://www.slm-solutions.com/products-and-solutions/machines/"
                target="_blank"
                className="bg-white text-blue hover:bg-transparent border hover:border hover:text-white h-[51px]"
              >
                SEE ALL PRODUCTS
              </Button>
            </div>
          </Parallax>
        </div>
      </div>

      <video
        className="header-bg-video"
        autoPlay
        loop
        muted
        onCanPlay={stopLoading}
      >
        <source src={HeaderBg} type="video/mp4" />
      </video>

      <div className="line hidden md:flex">
        <div className="bg-blue w-[287px]"></div>
        <div className="bg-yellow w-[392px]"></div>
      </div>
    </section>
  );
};
