import React from "react";
import { AnimeText } from "../components/AnimatedText";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import "@splidejs/react-splide/css/core";

function importAll(r) {
  return r.keys().map(r);
}
const compainyLogos = importAll(
  require.context("../assets/compaines/", false, /\.(png|jpe?g|svg)$/)
);

export const Section4 = (props) => {
  return (
    <section className="section4 relative bg-white py-20 md:pt-[70px] md:pb-[110px]">
      <div className="text-blue text-center items-center flex flex-col mx-auto max-w-2xl gap-y-6 px-6 md:px-0">
        <h2 className="title text-3xl md:text-4xl uppercase">
          <AnimeText>
            you’re ın <br className="hidden md:block" />
            <b>good company</b>
          </AnimeText>
        </h2>
        <p>
          <AnimeText>
            Join the ranks of industry leaders who trust and rely on our
            solutions for their success. Your success story starts here.
          </AnimeText>
        </p>
      </div>

      <div className="container mx-auto flex items-center justify-center mt-12 overflow-hidden">
        <Splide
          extensions={{ AutoScroll }}
          options={{
            arrows: false,
            type: "loop",
            gap: 50,
            autoWidth: true,
            drag: true,
            focus: "center",
            autoScroll: {
              speed: 0.6,
              pauseOnHover: false,
              pauseOnFocus: false,
            },
          }}
        >
          {compainyLogos.map((logo, index) => (
            <SplideSlide key={index}>
              <img
                src={logo}
                alt="Company"
                className="max-h-[60px] max-w-[150px]"
              />
            </SplideSlide>
          ))}
        </Splide>
      </div>
    </section>
  );
};
