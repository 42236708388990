import React from "react";
import { ReactComponent as ArrowSvg } from "../assets/arrow.svg";
import { ReactComponent as MapSvg } from "../assets/map.svg";
import { Button } from "../components/Button";
import { AnimeText } from "../components/AnimatedText";
import { motion } from "framer-motion";

export const Section6 = (props) => {
  return (
    <section className="section6 relative bg-white">
      <div className="text-blue text-center items-center flex flex-col mx-auto max-w-2xl gap-y-6 pt-[70px] pb-[55px] lg:pt-12 lg:pb-[68px] px-6 md:px-0">
        <h2 className="title text-3xl md:text-4xl uppercase">
          <AnimeText>
            Global Service Reach for
            <br className="hidden md:block" />
            <b> Your Convenience</b>
          </AnimeText>
        </h2>
        <p>
          <AnimeText>
            Explore our global service network, guaranteeing expert support at
            your fingertips, no matter where you're located.
          </AnimeText>
        </p>
        <Button
          href="https://www.slm-solutions.com/products-and-solutions/services/"
          target="_blank"
          className="bg-blue hover:bg-blue-700 text-white py-2 md:py-2.5 px-[13px] md:px-6 text-base"
        >
          LEARN MORE
          <motion.span
            className="ml-3 stroke-white"
            initial={{ transform: "translateX(-100%)", opacity: "0" }}
            whileInView={{ transform: "translateX(0)", opacity: "1" }}
            transition={{ duration: 0.75 }}
            viewport={{ once: true }}
          >
            <ArrowSvg />
          </motion.span>
        </Button>
      </div>

      <div className="container mx-auto py-0 px-6 md:px-20 md:mb-0 flex items-center justify-center relative">
        <div className="map w-full max-w-[1020px]">
          <MapSvg />
        </div>
      </div>
    </section>
  );
};
