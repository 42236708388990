import React from "react";
import { motion } from "framer-motion";

// Word wrapper
const Wrapper = (props) => {
  // We'll do this to prevent wrapping of words using CSS
  return (
    <span className="word-wrapper flex items-center">{props.children}</span>
  );
};

// Map API "type" vaules to JSX tag names
const tagMap = {
  p: "p",
  h1: "h1",
  h2: "h2",
  span: "span",
  div: "div",
  a: "a",
};

// AnimatedCharacters
// Handles the deconstruction of each word and character to setup for the
// individual character animations
const AnimatedCharacters = (props) => {
  // Framer Motion variant object, for controlling animation
  const item = {
    hidden: {
      y: "100%",
      opacity: 0,
      transition: { duration: 0.85 },
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.65 },
    },
  };

  //  Split each word of props.text into an array
  const splitWords = props.text.split(" ");

  // Create storage array
  const words = [];

  // Push each word into words array
  for (const [, item] of splitWords.entries()) {
    words.push([item]);
  }

  // Add a space ("\u00A0") to the end of each word
  words.map((word) => {
    return word.push("\u00A0");
  });

  if (words.length > 1) {
    words[words.length - 1].pop();
  }

  // Get the tag name from tagMap
  const Tag = tagMap[props.type];

  return (
    <Tag
      className={`flex items-center flex-wrap ${
        props.center ? "justify-center" : ""
      }`}
    >
      {words.map((word, index) => {
        return (
          // Wrap each word in the Wrapper component
          <Wrapper key={index}>
            {words[index].flat().map((element, index) => {
              return (
                <span
                  style={{
                    overflow: "hidden",
                    display: "inline-block",
                  }}
                  key={index}
                >
                  <motion.span
                    style={{ display: "inline-block" }}
                    variants={item}
                  >
                    {element}
                  </motion.span>
                </span>
              );
            })}
          </Wrapper>
        );
      })}
    </Tag>
  );
};

export default AnimatedCharacters;

export const AnimeText = (props) => {
  return (
    <motion.span
      style={{ overflow: "hidden", display: "inline-flex" }}
      // initial="hidden"
      // whileInView="visible"
      // variants={{
      //   visible: {
      //     transition: {
      //       staggerChildren: props.s || 0.035,
      //       delayChildren: props.d || 0,
      //     },
      //   },
      // }}
      initial={{ transform: "translateY(15%)" }}
      whileInView={{ transform: "translateY(0)" }}
      transition={{ duration: 1.5, delay: 0, ease: "linear" }}
      viewport={{ once: true }}
    >
      <motion.span
        // style={{ display: 'inline-block' }}
        initial={{ transform: "translateY(100%)", opacity: 0 }}
        whileInView={{ transform: "translateY(0)", opacity: 1 }}
        transition={{ duration: 0.9 }}
        viewport={{ once: true }}
      >
        {props.children}
      </motion.span>
      {/* <AnimatedCharacters type="span" text={props.children} center={props.center ?? true} /> */}
    </motion.span>
  );
};
