import React from "react";
import { ReactComponent as ArrowSvg } from "../assets/arrow.svg";
import ProductImage1 from "../assets/section3-1.png";
import ProductImage2 from "../assets/section3-2.png";
import ProductImage3 from "../assets/section3-3.png";
import ProductImage4 from "../assets/section3-4.png";
import ProductImage5 from "../assets/section3-5.png";
import { ReactComponent as ProductImage6 } from "../assets/section3-6.svg";
import { AnimeText } from "../components/AnimatedText";
import { motion } from "framer-motion";

export const Section3 = (props) => {
  const products = [
    {
      link: "https://www.slm-solutions.com/industries/aerospace-and-defense/",
      image: ProductImage1,
      label: "SPACE",
      className: "text-white",
    },
    {
      link: "https://www.slm-solutions.com/industries/automotive/",
      image: ProductImage2,
      label: "AUTOMOTIVE",
      className: "text-white",
    },
    {
      link: "https://www.slm-solutions.com/industries/energy/",
      image: ProductImage3,
      label: "ENERGY",
      className: "text-white",
    },
    {
      link: "https://www.slm-solutions.com/industries/tooling/",
      image: ProductImage4,
      label: "TOOLING",
      className: "text-white",
    },
    {
      link: "https://www.slm-solutions.com/industries/research",
      image: ProductImage5,
      label: "RESEARCH",
      className: "text-white",
    },
  ];

  return (
    <section className="section3 relative bg-white">
      <h2 className="title text-blue text-center text-3xl md:text-4xl uppercase font-bold pt-[81px] pb-[30px] lg:py-[70px]">
        <AnimeText>INDUSTRIES</AnimeText>
      </h2>

      <div className="container mx-auto">
        <div className="relative products mx-auto grid grid-cols-1 grid-rows-6 sm:grid-cols-2 sm:grid-rows-3 lg:grid-cols-3 lg:grid-rows-2 max-w-[397px] sm:max-w-[806px] lg:max-w-[1239px] gap-[12px] lg:gap-[24px]">
          {products.map((product, index) => (
            <div
              key={index}
              className={`item relative bg-yellow item p-[50px] lg:max-w-[397px] w-full ${product.className}`}
            >
              {product.image ? (
                <img
                  className="bg-image z-10 relative self-center max-h-[300px]"
                  alt={product.label}
                  src={product.image}
                />
              ) : (
                ""
              )}
              <a
                href={product.link}
                target="_blank"
                rel="noreferrer"
                className="absolute left-0 z-10 top-0 w-full h-full flex justify-center items-center uppercase text-3xl font-bold"
              >
                <h5 className="shadowed">{product.label}</h5>
              </a>
            </div>
          ))}

          <div className="item relative bg-yellow item p-[14px] lg:max-w-[397px] w-full ">
            <a
              href="https://www.slm-solutions.com/contact/"
              target="_blank"
              rel="noreferrer"
              className="absolute animated-button-custom left-0 top-0 w-full h-full pt-[34px] pl-[34px] pr-[20px] pb-[20px] flex flex-col justify-between uppercase text-4xl text-blue"
            >
              <span className="absolute bottom-0 left-0">
                <ProductImage6 />
              </span>
              <h5>
                AND <b>MORE...</b>
              </h5>
              <span className="stroke-blue text-base flex gap-x-2 font-bold text-right self-end">
                GET IN TOUCH
                <motion.span
                  className="stroke-blue anime"
                  initial={{ transform: "translateX(-100%)", opacity: "0" }}
                  whileInView={{ transform: "translateX(0)", opacity: "1" }}
                  transition={{ duration: 0.75 }}
                  viewport={{ once: true }}
                >
                  <ArrowSvg />
                </motion.span>
              </span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
