import React from "react";
import { ReactComponent as ArrowSvg } from "../assets/arrow.svg";
import HeaderBg from "../assets/section5-bg.mp4";
import { Button } from "../components/Button";
import { AnimeText } from "../components/AnimatedText";
import { motion } from "framer-motion";

export const Section5 = (props) => {
  return (
    <section className="section5 relative">
      <div className="section5-bg-video">
        <video autoPlay loop muted>
          <source src={HeaderBg} type="video/mp4" />
        </video>
      </div>

      <div className="text-white text-center items-center flex flex-col mx-auto max-w-2xl py-[158px] px-6 md:px-0">
        <h2 className="title text-3xl md:text-4xl uppercase mb-[15px]">
          <AnimeText>
            Explore the Building Blocks
            <br className="hidden md:block" />
            <b className="text-yellow"> of Excellence</b>
          </AnimeText>
        </h2>
        <p className="mb-[30px]">
          <AnimeText>
          Advancing excellence and meticulous innovation; our open architecture philosophy has empowered us and our collaborators to unleash the capabilities of more than 45 materials
          </AnimeText>
        </p>

        <Button
          href="https://www.slm-solutions.com/products-and-solutions/powders/"
          target="_blank"
          className="bg-yellow hover:bg-yellow-800 text-blue py-2 md:py-2.5 px-[13px] md:px-6 text-base"
        >
          DISCOVER MATERIALS
          <motion.span
            className="ml-3 stroke-blue"
            initial={{ transform: "translateX(-100%)", opacity: "0" }}
            whileInView={{ transform: "translateX(0)", opacity: "1" }}
            transition={{ duration: 0.75 }}
            viewport={{ once: true }}
          >
            <ArrowSvg />
          </motion.span>
        </Button>
      </div>
    </section>
  );
};
