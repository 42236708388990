import React from "react";

import Logo1 from "../assets/footer-1.png";
import Logo2 from "../assets/footer-2.png";
import Logo3 from "../assets/footer-3.png";
import Logo4 from "../assets/footer-4.png";

export const Footer = (props) => {
  const menuItems = [
    {
      label: "Products",
      url: "https://www.slm-solutions.com/products-and-solutions/machines/",
      target: "_blank",
    },
    {
      label: "Services",
      url: "https://www.slm-solutions.com/products-and-solutions/services/",
      target: "_blank",
    },
    {
      label: "Privacy",
      url: "https://www.slm-solutions.com/privacy-policy/",
      target: "_blank",
    },
    {
      label: "Contact",
      url: "https://www.slm-solutions.com/contact/",
      target: "_blank",
    },
  ];

  const socialMedia = [
    {
      label: "LinkedIn",
      url: "https://www.linkedin.com/company/nikonslmsolutions",
      target: "_blank",
    },
    {
      label: "Instagram",
      url: "https://www.instagram.com/NikonSLMSolutions/",
      target: "_blank",
    },
    {
      label: "X",
      url: "https://twitter.com/NikonSLM",
      target: "_blank",
    },
    {
      label: "Youtube",
      url: "https://www.youtube.com/@NikonSLMSolutions",
      target: "_blank",
    },
  ];

  const businessLinks = [
    {
      label: "Get a quote",
      url: "https://www.slm-solutions.com/get-a-quote/",
      target: "_blank",
    },
    {
      label: "Imprint",
      url: "https://www.slm-solutions.com/imprint/",
      target: "_blank",
    },
    {
      label: "General terms & conditions",
      url: "https://www.slm-solutions.com/general-terms-conditions/",
      target: "_blank",
    },
    {
      label: "Privacy policy",
      url: "https://www.slm-solutions.com/privacy-policy/",
      target: "_blank",
    },
  ];

  return (
    <footer className="footer bg-blue text-white">
      <div className="text-center items-center flex flex-col mx-auto max-w-2xl py-9 sm:py-12 lg:py-12">
        <img className="max-h-8" src="/logo512.png" alt="Nikon"></img>
        <div className="px-6 lg:px-0 my-9 lg:my-10 w-full"><hr className="w-full border-yellow" /></div>
        <div className="flex flex-wrap items-center justify-center text-sm font-bold gap-x-6 sm:gap-x-20 gap-y-3 mb-6 px-14 sm:px-6 lg:px-0">
          {menuItems.map((menu, id) => (
            <a
              href={menu.url}
              key={id}
              target={menu.target}
              className="hover:opacity-60 transition"
            >
              {menu.label}
            </a>
          ))}
        </div>

        <div className="text-xs mt-10 text-secondary">
          <div className="flex items-center justify-center gap-x-6 mb-4">
            Follow us on Social Media!
          </div>
          <div className="flex flex-wrap items-center justify-center text-white font-medium gap-x-6 mb-6">
            {socialMedia.map((menu, id) => (
              <a
                href={menu.url}
                key={id}
                target={menu.target}
                className="hover:opacity-60 transition"
              >
                {menu.label}
              </a>
            ))}
          </div>

          <div className="mt-20 sm:mt-8 mb-6 px-10 lg:px-0 flex flex-wrap items-center justify-center gap-x-5 gap-y-3 text-size-secondary">
            {businessLinks.map((menu, id) => (
              <a
                href={menu.url}
                key={id}
                target={menu.target}
                className="hover:text-white transition"
              >
                {menu.label}
              </a>
            ))}
          </div>
          <div className="text-size-secondary">Nikon SLM Solutions AG 2023</div>
        </div>
      </div>

      <div className="bg-white text-blue">
        <div className="container px-4 lg:px-[100px] mx-auto py-4 sm:py-6 flex items-center justify-between relative">
          <div className="flex items-center gap-x-2 sm:gap-x-3">
            <img src={Logo1} alt="Wir Fördern Wirtschaft" className="max-h-[32px] sm:max-h-[41px]"></img>
            <img src={Logo2} alt="Schleswig-Holstein" className="max-h-[21px] sm:max-h-[30px]"></img>
            <img src={Logo3} alt="Top 2022 Company" className="max-h-[31px] sm:max-h-[40px]"></img>
          </div>
          <div>
            <img src={Logo4} alt="Nikon Company" className="max-h-[36px] sm:max-h-[45px]"></img>
          </div>
        </div>
      </div>
    </footer>
  );
};
