import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { Button } from "./Button";
import { motion } from "framer-motion";
import { Dropdown } from "./Dropdown";
import { Transition } from "@headlessui/react";
import { ReactComponent as Chevron } from "../assets/chevron.svg";
import { ReactComponent as Direction } from "../assets/direction.svg";
import { ReactComponent as Toggle } from "../assets/toggle.svg";
import { ReactComponent as CloseIcon } from "../assets/close-icon.svg";
import Image1 from "../assets/section2-NXG-600E.png";
import Image2 from "../assets/section2-NXG-XII-601.png";
import Image3 from "../assets/section2-SLM500.png";
import Image4 from "../assets/section2-SLM280-PS.png";
import Image5 from "../assets/section2-SLM280-3.png";
import Image6 from "../assets/section2-SLM800.png";
import Image7 from "../assets/section2-SLM125.png";
import LargeGroup from "../assets/large-group.png";

const menuItems = [
  {
    label: "PRODUCTS",
    minWidth: "940px",
    itemFlex: "1 1 20%",
    childs: [
      {
        label: "NXG 600E",
        url: "https://am.slm-solutions.com/nxg-xii-600e/",
        target: "_blank",
        image: Image1,
      },
      {
        label: "SLM®500",
        url: "https://www.slm-solutions.com/products-and-solutions/machines/slm-500/",
        target: "_blank",
        image: Image3,
      },
      {
        label: "SLM®280 2.0",
        url: "https://www.slm-solutions.com/products-and-solutions/machines/slm-280/",
        target: "_blank",
        image: Image5,
      },
      {
        label: "Large Formats",
        url: "https://www.slm-solutions.com/company/news/detail/slm-solutions-acquires-adira-addcreative-technology-a-game-changer-for-ultra-large-part-production/",
        target: "_blank",
        image: LargeGroup,
        imgClass: "pl-0 pr-0 pt-0 pb-0 py-0",
      },
      {
        label: "NXG XII 600",
        url: "https://www.slm-pushing-the-limits.com/",
        target: "_blank",
        image: Image2,
      },
      {
        label: "SLM®280 PS",
        url: "https://www.slm-solutions.com/products-and-solutions/machines/slm-280-production-series/",
        target: "_blank",
        image: Image4,
      },
      {
        label: "SLM®800",
        url: "https://www.slm-solutions.com/products-and-solutions/machines/slm-800/",
        target: "_blank",
        image: Image6,
      },
      {
        label: "SLM®125",
        url: "https://www.slm-solutions.com/products-and-solutions/machines/slm-125/",
        target: "_blank",
        image: Image7,
      },
    ],
  },
  {
    label: "SERVICES",
    url: "https://www.slm-solutions.com/products-and-solutions/services/",
    target: "_blank",
  },
  {
    label: "COMPANY",
    minWidth: "410px",
    itemFlex: "1 1 20%",
    childs: [
      {
        label: "ABOUT US",
        url: "https://www.slm-solutions.com/company/about-us/",
        target: "_blank",
      },
      {
        label: "NEWS ROOM",
        url: "https://www.slm-solutions.com/company/news-room/",
        target: "_blank",
      },
      {
        label: "EVENTS",
        url: "https://www.slm-solutions.com/company/event-webinarcalender/",
        target: "_blank",
      },
      {
        label: "CAREERS",
        url: "https://careers.slm-solutions.com/",
        target: "_blank",
      },
      {
        label: "NEWSLETTER",
        url: "https://www.slm-solutions.com/newsletter/",
        target: "_blank",
      },
      {
        label: "COMPLIANCE",
        url: "https://www.slm-solutions.com/compliance/",
        target: "_blank",
      },
    ],
  },
  {
    label: "RESOURCES",
    url: "https://www.slm-solutions.com/media-hub/",
    target: "_blank",
  },
  {
    label: "INVESTOR",
    minWidth: "420px",
    childs: [
      {
        label: "FINANCIAL CALENDAR",
        url: "https://www.slm-solutions.com/investor-relations/financial-calendar/",
        target: "_blank",
      },
      {
        label: "PUBLIC TAKEOVER OFFER",
        url: "https://www.slm-solutions.com/investor-relations/public-takeover-offer/",
        target: "_blank",
      },
      {
        label: "ANNUAL GENERAL MEETING",
        url: "https://www.slm-solutions.com/investor-relations/annual-general-meetings/",
        target: "_blank",
      },
    ],
  },
];

const socialMedia = [
  {
    label: "LinkedIn",
    url: "https://www.linkedin.com/company/nikonslmsolutions",
    target: "_blank",
  },
  {
    label: "Instagram",
    url: "https://www.instagram.com/NikonSLMSolutions/",
    target: "_blank",
  },
  {
    label: "X",
    url: "https://twitter.com/NikonSLM",
    target: "_blank",
  },
  {
    label: "Youtube",
    url: "https://www.youtube.com/@NikonSLMSolutions",
    target: "_blank",
  },
];

const MobileDropdown = (props) => {
  const [collapse, setCollapse] = useState(null);

  useEffect(() => {
    document.body.style.overflow = props.isShow ? "hidden" : "visible";
  });

  return (
    <>
      <Button
        className={`text-white font-bold pl-2 pr-2 lg:hidden closeButton ${props.textColor} ${props.isShow ? "active" : ""}`}
        onClick={() => props.setIsShow(!props.isShow)}
      >
        {props.isShow ? <span className="not flex items-center justify-center" style={{ height:'20px', width: '28px' }}><CloseIcon /></span>: <Toggle /> }
      </Button>
      {createPortal(
        <Transition
          show={props.isShow}
          className="bg-blue fixed z-40 text-white top-0 left-0 w-full h-full transition-all duration-500 overflow-hidden"
          enterFrom="transform scale-95 opacity-0 max-h-0"
          enterTo="transform scale-100 opacity-100 max-h-screen"
          leaveFrom="transform scale-100 opacity-100 max-h-screen"
          leaveTo="transform scale-95 opacity-0 max-h-0"
        >
          <div className="mt-[88px] px-6 flex flex-col gap-y-4 uppercase h-full max-h-[calc(100%-88px)] overflow-y-auto pb-4">
            {menuItems.map((menu, index) =>
              menu.childs ? (
                <div key={index}>
                  <a
                    className="text-white font-bold text-xl"
                    href={menu.url}
                    onClick={() => {
                      index === collapse
                        ? setCollapse(null)
                        : setCollapse(index);
                    }}
                  >
                    <span className="flex justify-between items-center">
                      <span>{menu.label}</span>
                      <span
                        className={`stroke-white transition duration-200 ${
                          index === collapse ? "" : "-rotate-90"
                        }`}
                      >
                        <Chevron />
                      </span>
                    </span>
                  </a>
                  <Transition
                    show={collapse === index}
                    className="transition-all duration-500 overflow-hidden"
                    enterFrom="transform scale-95 opacity-0 max-h-0"
                    enterTo="transform scale-100 opacity-100 max-h-96"
                    leaveFrom="transform scale-100 opacity-100 max-h-96"
                    leaveTo="transform scale-95 opacity-0 max-h-0"
                  >
                    <div className="ml-[10px] mt-[10px] mb-[20px] flex flex-col gap-y-3">
                      {menu.childs.map((menu, index) => (
                        <a
                          key={index}
                          className="text-white text-lg opacity-80"
                          href={menu.url}
                          target={menu.target}
                        >
                          {menu.label}
                        </a>
                      ))}
                    </div>
                  </Transition>
                </div>
              ) : (
                <a
                  key={index}
                  className="text-white font-bold text-xl"
                  href={menu.url}
                  target={menu.target}
                >
                  <span className="flex justify-between items-center">
                    <span>{menu.label}</span>
                    <span className="stroke-white transition">
                      <Direction />
                    </span>
                  </span>
                </a>
              )
            )}

            <div className="flex flex-wrap items-center justify-center mt-auto capitalize text-sm text-white opacity-80 font-medium gap-x-6">
              {socialMedia.map((menu, id) => (
                <a href={menu.url} key={id} target={menu.target}>
                  {menu.label}
                </a>
              ))}
            </div>
          </div>
        </Transition>,
        document.body
      )}
    </>
  );
};

export const Navigation = (props) => {
  const [isShow, setIsShow] = useState(false);
  const textColor = props.isSticky && !isShow ? "text-blue stroke-blue" : "text-white stroke-white";
  const logo = props.isSticky && !isShow ? "/logo-dark.png" : "/logo512.png";

  return (
    <motion.nav
      className={`navigation z-50 relative ${props.className} ${isShow ? 'active' : ''}`}
      initial={{ transform: "translateY(-100%)", opacity: "0" }}
      whileInView={{
        transform: "translateY(0)",
        opacity: "1",
        transitionEnd: {
          transform: "unset",
        },
      }}
      transition={{ duration: 1 }}
      viewport={{ once: true }}
    >
      <div className="container px-4 lg:px-[100px] mx-auto flex justify-between">
        <img src={logo} alt="SLM" className="w-[142px] md:w-[164px]" />

        <div
          className={`gap-x-2 md:gap-x-5 flex items-center text-sm font-bold ${textColor}`}
        >
          {/* Web Dropdown */}
          <div className="items-center gap-x-5 hidden lg:flex">
            {menuItems.map((menu, id) =>
              menu.childs ? (
                <Dropdown key={id} label={menu.label} className="pt-7">
                  <div className="bg-[#F6F6F6] shadow-md text-blue px-[40px] py-[55px] rounded flex items-start flex-col">
                    <label className="uppercase text-base border-b-2 border-yellow pb-1 px-2 -ml-2">
                      {menu.label}
                    </label>
                    <div
                      className="flex flex-wrap mt-[24px] gap-x-14 gap-y-10"
                      style={{ minWidth: menu.minWidth }}
                    >
                      {menu.childs.map((item, id) => (
                        <a
                          key={id}
                          href={item.url}
                          target={item.target}
                          className={`flex items-center gap-x-3 hover:opacity-60 transition ${item.className}`}
                          style={{ flex: menu.itemFlex }}
                        >
                          {item.image && (
                            <span className="min-w-[70px] min-h-[70px] rounded bg-white flex items-center justify-center">
                              <img
                                src={item.image}
                                className={`max-h-[70px] rounded px-2 py-1 ${item.imgClass}`}
                                alt={item.label}
                              />
                            </span>
                          )}
                          <span className="w-full">{item.label}</span>
                        </a>
                      ))}
                    </div>
                  </div>
                </Dropdown>
              ) : (
                <a
                  href={menu.url}
                  key={id}
                  target={menu.target}
                  className="hover:opacity-60 transition flex items-center justify-center gap-x-2"
                >
                  {menu.label}
                </a>
              )
            )}
          </div>

          <Button
            href="https://www.slm-solutions.com/contact/"
            target="_blank"
            className="text-blue bg-yellow hover:bg-yellow-800 font-bold pl-2 pr-2 md:pl-5 md:pr-5"
          >
            CONTACT US
          </Button>

          <MobileDropdown textColor={textColor} isShow={isShow} setIsShow={setIsShow}></MobileDropdown>
        </div>
      </div>
    </motion.nav>
  );
};
